<template>
	<div class="ActivityAuditDetail">
		<w-navTab titleText="任务详情"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="header">
				<div class="header-title">{{detail.title}}</div>
				<div class="header-text">申请时间：{{detail.apply_audit_time}}</div>
				<div class="header-footer">
					<div class="header-footer-item">{{detail.group_name}}</div>
					<div class="header-footer-item item-color-red">{{detail.county_name}}</div>
				</div>
			</div>
			<div class="body">
				<div class="body-content">
					<div class="body-content-item">
						<div class="content-item-label">活动时间：</div>
						<div class="content-item-text">
							{{convertTime(detail.begin_date,detail.begin_time)}} - {{convertTime(detail.finish_date,detail.finish_time)}}
						</div>
					</div>
					<div class="body-content-item">
						<div class="content-item-label">需求人数：</div>
						<div class="content-item-text">{{detail.people_number}}人</div>
					</div>
					<div class="body-content-item">
						<div class="content-item-label">打卡位置：</div>
						<div class="content-item-text">{{detail.address}}</div>
					</div>
					<div class="body-content-item">
						<div class="content-item-label">打卡范围：</div>
						<div class="content-item-text">{{detail.distance}}米</div>
					</div>
					<div class="body-content-item">
						<div class="content-item-label label-active">负责人电话：</div>
						<div class="content-item-text">{{detail.contact_telephone}}</div>
					</div>
					<div class="body-content-phone" @click="callPhone(detail.contact_telephone)">
						<div class="phone-text">联系他</div>
						<div class="phone-icon">
							<img src="@/assets/img/u_icon129.png" alt="">
						</div>
					</div>
				</div>
				<div id="map"></div>
			</div>
			<div class="middle">
				<div class="middle-title">活动简介</div>
				<div class="middle-text">
					{{detail.intro}}
				</div>
			</div>
			<div class="footer">
				<template v-if="detail.audit_status == 4">
					<div class="footer-button" @click="onRefuse">拒绝发布</div>
					<div class="footer-button button-color-red" @click="onConsent">同意发布</div>
				</template>
				<div class="footer-button button-color-gray" v-if="detail.audit_status == 2">已同意发布</div>
				<div class="footer-button button-color-gray" v-if="detail.audit_status == 3">已拒绝发布</div>
			</div>
		</div>
	</div>
</template>

<script>
	import User from '@/api/user';
	import Event from '@/store/event';
	import VantVendor from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';
	import Moment from '@/vendor/moment';
	
	export default {
		name: 'ActivityAuditDetail',
		data() {
			return {
				id: 1,
				map: null,
				circle: null,
				markerLayer: null,
				loadingPage: true,
				detail: ''
			}
		},
		created() {
			this.id = this.$route.params.id
			this.getActivityAuditDetails()
		},
		methods: {
			getActivityAuditDetails() {
				let params = {
					activity_id: this.id
				}
				User.activityAuditDetails(params)
					.then(result => {
						this.detail = result.data
						this.loadingPage = false
						this.createMap(this.detail.latitude, this.detail.longitude);
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					})
			},
			createMap(latitude, longitude) {
				if (this.map !== null) {
					return false;
				}

				let center = new TMap.LatLng(latitude, longitude);

				// 初始化地图
				this.map = new TMap.Map('map', {
					rotation: 0,
					pitch: 0,
					zoom: 15,
					center: center,
					showControl: false
				});

				// 添加地图控件
				let control = this.map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
				let rotation = this.map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);

				this.map.addControl(control);
				this.map.addControl(rotation);

				// 绘制打卡范围
				this.circle = new TMap.MultiCircle({
					map: this.map,
					geometries: [{
						id: 'circle',
						center: center,
						radius: this.detail.distance
					}]
				});

				// 绘制标记点
				this.markerLayer = new TMap.MultiMarker({
					map: this.map,
					styles: {
						activityMarkerStyle: new TMap.MarkerStyle({
							width: 20,
							height: 30,
							anchor: {
								x: 10,
								y: 30
							}
						}),
						UserMarkerStyle: new TMap.MarkerStyle({
							width: 30,
							height: 30,
							anchor: {
								x: 15,
								y: 15
							},
							src: require('@/assets/img/position.png')
						})
					},
					geometries: [{
						id: 'activityMarker',
						styleId: 'activityMarkerStyle',
						position: center
					}]
				});
			},
			// 拨打电话
			callPhone(phone) {
				window.location.href = 'tel://' + phone;
			},
			// 拒绝发布
			onRefuse() {
				VantVendor.Dialog.confirm({
					title: '温馨提示',
					message: '您确认拒绝发布吗？'
				}).then(() => {
					User.activityAuditPresent({
						activity_id: this.id, status: 3
					}).then(result => {
						this.detail.audit_status = 3
						VantVendor.Toast.success('拒绝成功')
						Event.$emit('removeItem', this.id)
					}).catch(error => {
						console.log(error);
					})
				}).catch(() => {
					console.log('取消')
				})
			},
			// 同意发布
			onConsent() {
				VantVendor.Dialog.confirm({
					title: '温馨提示',
					message: '您确认同意发布吗？'
				}).then(() => {
					User.activityAuditPresent({
						activity_id: this.id, status: 2
					}).then(result => {
						this.detail.audit_status = 2
						VantVendor.Toast.success('同意成功')
						Event.$emit('removeItem', this.id)
					}).catch(error => {
						console.log(error);
					})
				}).catch(() => {
					console.log('取消')
				})
			},
			convertTime(time1,time2){
				let time = time1 + ' ' + time2
				return Moment(time).format('YYYY-MM-DD HH:mm:ss')
			},
		},
		components: {
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.ActivityAuditDetail {
		width: 100%;
		height: 100vh;
		min-height: 100vh;
		background: #fff;

		.container {
			padding-bottom: 100px;
			box-sizing: border-box;

			.header {
				padding: 16px 16px 0;
				box-sizing: border-box;

				.header-title {
					text-align: center;
					color: #333;
					font-size: 20px;
					font-weight: bold;
					line-height: 30px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				.header-text {
					text-align: center;
					color: #999;
					font-size: 14px;
					line-height: 16px;
					margin-top: 7px;
				}

				.header-footer {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 16px;

					.header-footer-item {
						padding: 2px 8px;
						color: #37F;
						font-size: 14px;
						border-radius: 29px;
						background: rgba(51, 119, 255, 0.10);
						box-sizing: border-box;
						margin: 0 6px;

						&.item-color-red {
							color: #FF6969;
							background: rgba(255, 105, 105, 0.10);
						}
					}
				}
			}

			.body {
				padding: 16px;
				border-bottom: 6px solid #F2F2F2;
				box-sizing: border-box;

				.body-content {
					padding: 12px 14px;
					box-sizing: border-box;
					border-radius: 8px;
					background: #F9F9F9;
					margin-bottom: 8px;
					position: relative;

					.body-content-item {
						display: flex;
						margin-top: 8px;

						&.body-content-item:first-child {
							margin-top: 0;
						}

						.content-item-label {
							width: 80px;
							min-width: 80px;
							color: #999;
							font-size: 16px;
							line-height: 23px;
							
							&.label-active{
								width: 100px;
								min-width: 100px;
							}
						}

						.content-item-text {
							color: #333;
							font-size: 16px;
							line-height: 23px;
						}
					}

					.body-content-phone {
						position: absolute;
						right: 12px;
						bottom: 14px;
						display: flex;
						align-items: center;

						.phone-text {
							color: #333;
							font-size: 14px;
							line-height: 21px;
							margin-right: 6px;
						}

						.phone-icon {
							width: 20px;
							height: 20px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
					}
				}

				/deep/#map {
					width: 343px;
					height: 243px;

					.logo-text,
					a {
						display: none;
					}
				}
			}

			.middle {
				padding: 16px 16px 0;
				box-sizing: border-box;

				.middle-title {
					color: #333;
					font-size: 18px;
					font-weight: bold;
					line-height: 24px;
					display: flex;
					align-items: center;

					&::before {
						content: '';
						display: block;
						width: 4px;
						height: 15px;
						border-radius: 22px;
						background: #37F;
						margin-right: 8px;
					}
				}

				.middle-text {
					color: #777;
					font-size: 16px;
					line-height: 26px;
					margin-top: 12px;
				}
			}

			.footer {
				width: 100%;
				position: fixed;
				left: 0;
				bottom: 0;
				padding: 16px;
				border-top: 1px solid #F4F4F4;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background: #FFF;

				.footer-button {
					flex: 1;
					padding: 8px 0;
					color: #FF6969;
					text-align: center;
					font-size: 18px;
					line-height: 27px;
					border-radius: 6px;
					border: 1px solid #FF6969;
					box-sizing: border-box;

					&.button-color-red {
						color: #FFF;
						background: #37F;
						border: 1px solid #37F;
						box-sizing: border-box;
						margin-left: 11px;
					}
					
					&.button-color-gray{
						color: #FFF;
						background: #CECECE;
						border: 1px solid #CECECE;
						box-sizing: border-box;
					}
				}
			}
		}
	}
</style>